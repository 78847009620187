export enum Colors {
  PURPLE = "var(--purple)",
  PURPLE2 = "var(--purple2)",
  PURPLE_DARK = "var(--purple-dark)",
  WHITE = "var(--white)",
  BLACK = "var(--black)",
  BLACK_LIGHT = "var(--black-light)",
  BLACK_LIGHTER = "var(--black-lighter)",
  GREY = "var(--grey)",
}

export const Gradients = {
  PURPLE_GRADIENT: "var(--purple-gradient)",
  DARK_GRADIENT: "var(--black-light)",
};
