import { Anchor, Group, Title } from "@mantine/core";
import { Colors } from "../../styles/colors";
import { PageRoute } from "../../navigation/routes";
import classes from "../../styles/animations.module.css";

type NavLinkProps = {
  text: string;
  route: PageRoute;
  onClick?: () => void;
};

export const NavLink = ({ text, route, onClick }: NavLinkProps) => (
  <Anchor
    href={route}
    c={Colors.WHITE}
    underline="never"
    className={classes.hoverUnderline}
    onClick={onClick}
  >
    <Title order={6}>{text}</Title>
  </Anchor>
);

type NavigationLinksProps = {
  onLinkClick?: () => void;
  variant?: "header" | "menu";
  showOnlyAdditional?: boolean;
};

export const NavigationLinks = ({
  onLinkClick,
  variant = "header",
  showOnlyAdditional = false,
}: NavigationLinksProps) => {
  const mainLinks = (
    <>
      <NavLink text={"ABOUT"} route={PageRoute.ABOUT} onClick={onLinkClick} />
      <NavLink
        text={"CONTACT"}
        route={PageRoute.CONTACT}
        onClick={onLinkClick}
      />
      <NavLink
        text={"PUZZLES"}
        route={PageRoute.PUZZLES}
        onClick={onLinkClick}
      />
    </>
  );

  const additionalLinks = (
    <>
      <NavLink
        text={"PRIVACY POLICY"}
        route={PageRoute.PRIVACY}
        onClick={onLinkClick}
      />
      <NavLink
        text={"STANDARDS"}
        route={PageRoute.STANDARDS}
        onClick={onLinkClick}
      />
      <NavLink
        text={"TERMS & CONDITIONS"}
        route={PageRoute.TERMS}
        onClick={onLinkClick}
      />
    </>
  );

  return (
    <Group
      gap={variant === "header" ? "xl" : "md"}
      style={
        variant === "menu"
          ? {
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0.5rem 1rem",
            }
          : undefined
      }
    >
      {showOnlyAdditional ? (
        additionalLinks
      ) : variant === "header" ? (
        mainLinks
      ) : (
        <>
          {mainLinks}
          {additionalLinks}
        </>
      )}
    </Group>
  );
};
