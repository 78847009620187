export enum PuzzleType {
  WORD_IT = "WordIt",
  SORT_IT = "SortIt",
  BOX_IT = "BoxIt",
  DECRYPT_IT = "DecryptIt",
  SPELL_IT = "SpellIt",
  FIND_IT = "FindIt",
  PAIR_IT = "PairIt",
  CROSS_IT = "CrossIt",
  MATCH_IT = "MatchIt",
}

export const PUZZLE_DESCRIPTIONS: Record<PuzzleType, string> = {
  [PuzzleType.WORD_IT]:
    "Swipe in all directions to find the required number of words hidden in a grid",
  [PuzzleType.SORT_IT]: "Arrange rings to perfectly match a given solution",
  [PuzzleType.BOX_IT]:
    "Fill a grid completely by creating squares and rectangles with the appropriate areas",
  [PuzzleType.DECRYPT_IT]:
    "Guess a secret code using clues shown after each guess",
  [PuzzleType.SPELL_IT]: "Create a word using one letter from each column",
  [PuzzleType.FIND_IT]:
    "Find all the hidden words in a grid based on their starting letters",
  [PuzzleType.PAIR_IT]: "Find all unique pairs of numbers in a grid",
  [PuzzleType.CROSS_IT]: "Solve a mini crossword",
  [PuzzleType.MATCH_IT]: "Find matches on adjacent sides by spinning tiles",
};

export const convertPuzzleTypeToCamelCase = (puzzleType: PuzzleType) =>
  puzzleType.charAt(0).toLowerCase() + puzzleType.slice(1);
