import { Anchor, Group } from "@mantine/core";
import { Colors } from "../../styles/colors";
import { ReactComponent as TikTokLogo } from "./TikTok.svg";
import { ReactComponent as InstagramLogo } from "./InstagramLogo.svg";
import { ReactComponent as LinkedInLogo } from "./LinkedInLogo.svg";
import { ReactComponent as DiscordLogo } from "./DiscordLogo.svg";
import { PropsWithChildren } from "react";
import classes from "../../styles/animations.module.css";

const SocialLink = ({
  href,
  children,
}: { href: string } & PropsWithChildren) => (
  <Anchor
    href={href}
    c={Colors.WHITE}
    underline={"never"}
    target={"_blank"}
    className={classes.socialLink}
  >
    {children}
  </Anchor>
);

type SocialLinksProps = {
  variant?: "header" | "menu";
};

export const SocialLinks = ({ variant = "header" }: SocialLinksProps) => (
  <Group
    gap="md"
    style={
      variant === "menu"
        ? {
            flexDirection: "row",
            padding: "0.5rem 1rem",
            justifyContent: "center",
            width: "100%",
          }
        : undefined
    }
  >
    <SocialLink href={"https://www.tiktok.com/@puzzlit_app"}>
      <TikTokLogo />
    </SocialLink>
    <SocialLink href={"https://www.instagram.com/puzzlitapp"}>
      <InstagramLogo />
    </SocialLink>
    <SocialLink href={"https://www.linkedin.com/company/puzzlit"}>
      <LinkedInLogo />
    </SocialLink>
    <SocialLink href={"https://discord.gg/YTpJxzxdjk"}>
      <DiscordLogo />
    </SocialLink>
  </Group>
);
