import { Box, Divider, Stack, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { PuzzleCard } from "./PuzzleCard";
import { PuzzleType } from "./puzzles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const PuzzlesCarousel = () => {
  const theme = useMantineTheme();
  const isMobile =
    useMediaQuery(`(max-width: ${theme.breakpoints.sm})`) || false;
  const puzzleTypes = Object.values(PuzzleType).sort((a, b) =>
    a.localeCompare(b)
  );

  const breakpoints = {
    sm: parseInt(theme.breakpoints.sm.replace("em", "")) * 16,
    md: parseInt(theme.breakpoints.md.replace("em", "")) * 16,
    lg: parseInt(theme.breakpoints.lg.replace("em", "")) * 16,
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: "0px",
    swipeToSlide: true,
    cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: breakpoints.lg,
        settings: {
          slidesToShow: 2,
          centerPadding: "0px",
        },
      },
    ],
  };

  if (isMobile) {
    return (
      <Stack gap="xl" p="md">
        {puzzleTypes.map((puzzleType) => (
          <>
            <Box key={puzzleType}>
              <PuzzleCard puzzleType={puzzleType} isMobile={isMobile} />
            </Box>
            <Divider pb="xl" />
          </>
        ))}
      </Stack>
    );
  }

  return (
    <Box
      style={{
        width: "90vw",
        marginLeft: "calc(-45vw + 50%)",
        marginRight: "calc(-45vw + 50%)",
        ".slick-slide": {
          padding: theme.spacing.md,
        },
        ".slick-list": {
          padding: "0 5% !important",
        },
        ".slick-prev, .slick-next": {
          width: "40px",
          height: "40px",
          zIndex: 1,
          "&:before": {
            fontSize: "40px",
            color: "var(--purple-20)",
          },
        },
        ".slick-prev": {
          left: "2%",
        },
        ".slick-next": {
          right: "2%",
        },
        ".slick-current": {
          transform: "scale(1.05)",
          transition: "transform 0.3s ease",
        },
      }}
    >
      <Slider {...settings}>
        {puzzleTypes.map((puzzleType) => (
          <Box key={puzzleType} p="md">
            <PuzzleCard puzzleType={puzzleType} isMobile={isMobile} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
