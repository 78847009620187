import { Container } from "@mantine/core";
import { PuzzlesCarousel } from "./PuzzlesCarousel";

export const PuzzlesPage = () => (
  <Container size="lg" py="xl">
    <PuzzlesCarousel />
  </Container>
);

export default PuzzlesPage;
