import { Box } from "@mantine/core";
import { useState, useRef } from "react";
import { convertPuzzleTypeToCamelCase, PuzzleType } from "./puzzles";

type PuzzleVideoProps = {
  puzzleType: PuzzleType;
};

export const PuzzleVideo = ({ puzzleType }: PuzzleVideoProps) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const puzzleTypeCamelCase = convertPuzzleTypeToCamelCase(puzzleType);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={togglePlayPause}
    >
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      >
        <source
          src={`game_examples/${puzzleTypeCamelCase}.mp4`}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      {/* Play/Pause Overlay */}
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: isPlaying ? 0 : 1,
          transition: "opacity 0.2s ease",
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="white"
          style={{ marginLeft: isPlaying ? 0 : "3px" }}
        >
          {isPlaying ? (
            <path d="M6 4h4v16H6V4zm8 0h4v16h-4V4z" />
          ) : (
            <path d="M8 5v14l11-7z" />
          )}
        </svg>
      </Box>
    </Box>
  );
};
