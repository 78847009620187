import { Box, Container, Stack, Anchor } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { AnimatedLogo } from "./components/AnimatedLogo";
import { Tagline } from "./components/Tagline";
import { DownloadButton } from "./components/download_button/DownloadButton";
import { ReviewCards } from "./components/ReviewCards";
import { PromoVideo } from "./components/PromoVideo";
import classes from "../../styles/animations.module.css";
import { Colors } from "../../styles/colors";
export const HomePage = () => {
  const largeScreen = useMediaQuery("(min-width: 56.25em)") ?? false;

  return (
    <Container size="xl">
      <Stack
        align="center"
        justify="center"
        gap={50}
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          minHeight: "100vh",
          paddingBottom: "5rem",
          paddingInline: largeScreen ? 0 : 0,
        }}
      >
        {/* Hero Section */}
        <Stack
          align="center"
          gap="lg"
          style={{
            width: "100%",
            padding: largeScreen ? 0 : "2rem",
            margin: "0 auto",
          }}
        >
          <Box
            style={{
              width: "100%",
              overflow: "visible",
              padding: "2rem",
              margin: "-2rem",
              paddingTop: "5rem",
            }}
          >
            <AnimatedLogo largeScreen={largeScreen} />
          </Box>
          <Tagline largeScreen={largeScreen} />

          <Box
            style={{
              marginTop: "2rem",
              width: "100%",
              maxWidth: "1000px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <DownloadButton largeScreen={largeScreen} />
            <Anchor
              href="https://docs.google.com/forms/d/e/1FAIpQLSdkrTXOp_8YlxRKre96WmASupdfeGNEhNTyfhUx5jatBC-31w/viewform"
              c={Colors.WHITE}
              underline={"never"}
              target={"_blank"}
              className={classes.purpleHoverLink}
            >
              Join Android Waitlist
            </Anchor>
          </Box>
        </Stack>

        {/* Promo Video Section */}
        <Box style={{ width: "100%" }}>
          <PromoVideo largeScreen={largeScreen} />
        </Box>

        {/* Feature Highlights */}
        <Stack
          gap="xl"
          style={{
            width: "100%",
            textAlign: "center",
            paddingInline: largeScreen ? 0 : "1rem",
          }}
        >
          <ReviewCards largeScreen={largeScreen} />
        </Stack>
      </Stack>
    </Container>
  );
};

export default HomePage;
