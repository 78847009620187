import { Box, Text, Title, Group } from "@mantine/core";
import { Colors } from "../../../styles/colors";
import { TeamMemberProps } from "../types/team";

export function TeamMember({ name, role, bio, imageUrl }: TeamMemberProps) {
  return (
    <Group
      align="flex-start"
      justify="space-between"
      style={{
        padding: "2rem",
        borderRadius: "15px",
        background: "var(--glass-bg)",
        backdropFilter: "var(--glass-blur-sm)",
        border: "1px solid var(--purple-15)",
      }}
    >
      <Box style={{ flex: 1 }}>
        <Title order={3} mb="xs" style={{ color: Colors.PURPLE }}>
          {name}
        </Title>
        <Text size="lg" mb="md" c="dimmed">
          {role}
        </Text>
        <Text>{bio}</Text>
      </Box>

      <Box
        style={{
          minWidth: "200px",
          marginLeft: "2rem",
        }}
      >
        <Box
          style={{
            borderRadius: "15px",
            overflow: "hidden",
          }}
        >
          <img
            src={`/headshots/${imageUrl}`}
            alt={name}
            style={{
              width: "200px",
              height: "200px",
              objectFit: "cover",
              display: "block",
              transition: "filter 0.3s ease",
              filter: "grayscale(100%)",
            }}
          />
        </Box>
      </Box>
    </Group>
  );
}
