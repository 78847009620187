import { Stack, Box, Text } from "@mantine/core";
import {
  PuzzleType,
  PUZZLE_DESCRIPTIONS,
  convertPuzzleTypeToCamelCase,
} from "./puzzles";
import { PuzzleVideo } from "./PuzzleVideo";

export const PuzzleCard = ({
  puzzleType,
  isMobile,
}: {
  puzzleType: PuzzleType;
  isMobile: boolean;
}) => {
  const camelCase = convertPuzzleTypeToCamelCase(puzzleType);

  return (
    <Stack gap="xl" align="center">
      {/* Logo Section */}
      <Box
        style={{
          width: "150px",
          height: "120px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={`game_logos/${camelCase}.svg`}
          alt={`${puzzleType} logo`}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            filter: "brightness(0) invert(1)",
          }}
        />
      </Box>

      {/* Description Section */}
      <Box
        style={{
          width: isMobile ? "100%" : "75%",
          padding: "1rem",
          border: "1px solid var(--purple-15)",
          borderRadius: "8px",
          background: "var(--purple-10)",
          height: isMobile ? undefined : "175px",
        }}
      >
        <Stack align="center">
          <Text size="xl">{puzzleType}</Text>
          <Text ta="center" c="dimmed">
            {PUZZLE_DESCRIPTIONS[puzzleType]}
          </Text>
        </Stack>
      </Box>

      {/* Example Section */}
      <Box
        style={{
          width: "300px",
          borderRadius: "8px",
        }}
      >
        <PuzzleVideo puzzleType={puzzleType} />
      </Box>
    </Stack>
  );
};

export default PuzzleCard;
